import React from 'react';

const DateTimeDisplay = ({ value, type }) => {
  if (value == 0){
    return <></>
  }
  return (
    <span>{value} {type} </span>
  );
};

export default DateTimeDisplay;