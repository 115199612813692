import React from "react";
import CountdownTimer from "../countdown/CountdownTimer";
import Initials from "../signIn";
import FromProg from "../../assets/FromProg.svg";
import styled from "styled-components";
import { NoLoginMsg } from "../user_message";

const StyledInitials = styled(Initials)`
  font-size: 30pt;
  background-color: blueviolet;
  border-radius: 100%;
  margin: 15px;
  padding: 1em;
`;

const NoCreditLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function CreditComponent({ user, credits, endDate, className }) {
  if (user === undefined) {
    return <NoLoginMsg />;
  }
  const emptyCredits = credits == 0;
  let creditText = "";
  if (credits === undefined || credits === null) {
    creditText = "??";
  } else {
    creditText = credits.toString();
  }

  //   const debugTarget = new Date("2042-12-17T03:24:00");
  return (
    <div className={className}>
      <StyledInitials user={user} className="initials" />
      <p className="title">Remaining credits:</p>
      <div className="creditContainer">
        <FromProg className="fromage_icon" />
        <p> x {credits}</p>
      </div>
      {/* {emptyCredits && <CountdownTimer targetDate={endDate} />} */}
      <CountdownTimer targetDate={endDate} />
    </div>
  );
}

const StyledCreditComponent = styled(CreditComponent)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  @media screen and (max-width: 1382px) {
    flex-direction: column;
  }
  p {
    margin: 0;
  }
  .fromage_icon {
    height: auto;
  }
  .creditContainer {
    margin: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .counter {
    display: inline;
  }
`;

const SideBarCreditComponent = styled(StyledCreditComponent)`
  flex-direction: column !important;
  .fromage_icon {
    height: auto;
    display: inline-box;
    width: 65px;
  }
  .title {
    text-align: center;
    @media screen and (max-width: 1382px) {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  p {
    display: inline-box;
    font-size: 33px;
  }
`;

const InlineCreditComponent = styled(StyledCreditComponent)`
  align-items: center !important;
  font-size: ${({ theme }) => theme.body.fontsize};
  background-color: ${({ theme }) => theme.maincolor.light_grey};
  color: white;
  border-radius: 0.8em;
  margin: 1em;
  padding-right: 1em;
  padding-left: 1em;

  .title {
    @media screen and (max-width: 1382px) {
      display: none;
    }
  }
  .fromage_icon {
    width: 2.3em;
    /* height: auto; */
    margin: 0.3em;
  }
  .initials {
    display: none;
  }
`;

const CreditComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export { SideBarCreditComponent, InlineCreditComponent, CreditComponentWrapper };
