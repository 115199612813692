import { graphql } from "gatsby";
import React, { useContext } from "react";
import LaboJoyride from "../../../content/labo_joyride.json";
import { isOnline } from "../../components/constants";
import { UserCreditContext } from "../../components/credits/credits_context";
import {
  CreditComponentWrapper,
  InlineCreditComponent,
  SideBarCreditComponent,
} from "../../components/credits/credits_msg";
import Tutorial from "../../components/joyride";
import Layout from "../../components/layout";
import {
  JoyrideStart,
  LaboThumbnail,
  LaboThumbnailCloud,
} from "../../components/pages/labo";
import PageHeading from "../../components/page_heading";
import SideBar from "../../components/sidebar";

const LaboPage = ({ data }) => {
  const { userInfo, isAuthenticated } = useContext(UserCreditContext);

  const creditParams = {
    user: userInfo.user,
    credits: userInfo.credits,
    enddate: userInfo.endDate,
  };
  const creditInline = (
    <CreditComponentWrapper>
      <InlineCreditComponent
        {...{ ...creditParams, className: "jr-credits-inline" }}
      />
    </CreditComponentWrapper>
  );

  const sidebarCreditComp = <SideBarCreditComponent {...creditParams} />;
  const creditSidebar = (
    <SideBar elements={sidebarCreditComp} className="jr-credits-sidebar" />
  );

  const cheeseExpeData = {
    url: "/labo/cheese_gen_expe/",
    title: "Cheese Generation",
    tags: ["aws", "gan", "python"],
    gatsbyImageData: data.cheeseExpeImgNode.childImageSharp.gatsbyImageData,
    description: "Generate a cheese using a GAN network by clicking a button",
  };

  const facesExpeData = {
    url: "/labo/faces_expe/",
    title: "Face Warping",
    tags: ["aws", "gan", "python"],
    gatsbyImageData: data.faceExpeImgNode.childImageSharp.gatsbyImageData,
    description: "Warp a face into another one using variational autoencoders",
  };

  const sumExpeData = {
    url: "/labo/sum_expe/",
    title: "Sum experiment",
    tags: ["foo", "bar"],
    gatsbyImageData: data.sumExpeImgNode.childImageSharp.gatsbyImageData,
    description: "Perform an exciting sum to debug your app!",
  };
  return (
    <>
      {creditSidebar}
      <Layout heroSrc={undefined} iconName="labo" pageWidth="80em">
        {true && <Tutorial steps={LaboJoyride.steps} name={"labo-joyride"} />}
        <PageHeading
          headingText="Laboratory"
          subHeadingText="Interactive experiments"
        />
        <JoyrideStart className="jr-labo-start" />
        {creditInline}
        <LaboThumbnailCloud className="jr-labo-experiments">
          <LaboThumbnail data={cheeseExpeData} />
          <LaboThumbnail data={facesExpeData} />
          {!isOnline && <LaboThumbnail data={sumExpeData} />}
        </LaboThumbnailCloud>
        <p> If you are interested in the technical details of the backend, have a look <a href="/blog/fromprog_arch">here</a> </p>
      </Layout>
    </>
  );
};
export const query = graphql`
  {
    cheeseExpeImgNode: file(
      sourceInstanceName: { eq: "assets" }
      relativeDirectory: { eq: "labo" }
      name: { eq: "cheeses" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    faceExpeImgNode: file(
      sourceInstanceName: { eq: "assets" }
      relativeDirectory: { eq: "labo" }
      name: { eq: "face_filler" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sumExpeImgNode: file(
      sourceInstanceName: { eq: "assets" }
      relativeDirectory: { eq: "labo" }
      name: { eq: "maths" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
export default LaboPage;
