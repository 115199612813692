import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Thumbnail } from "../../components/thumbnail";

function AuthVariableInput({ isAuthenticated, callBack }) {
  const exampleValue = "3 5";
  useEffect(() => {
    callBack(exampleValue);
  }, []);
  const props = {
    onChange: (event) => callBack(event.target.value),
    placeholder: `e.g: ${exampleValue}`,
    ...(!isAuthenticated && { value: exampleValue }),
  };
  return <input {...props}></input>;
}

const JoyrideStart = styled.div`
  width: 100%;
  height: 0;
`;

const ExpeCloud = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  width: 100%;
  gap: 10px;
`;

const ExpeImgStyle = css`
  width: 15em;
  height: 14em;
  border-radius: 5%;
`;

const ExpeImage = styled.img`
  ${ExpeImgStyle}
`;

const LaboThumbnail = styled(Thumbnail)`
  width:20em;
  margin: 0 0 1.5em;
  .resource-title {
    /*TODO: find a way to embed the imported css */
    /* ${TitleCssStyle}; */
    color: white;
    font-family: "Lato", "Open Sans", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 800;
    color: white;
    height: 3em;
    box-sizing: border-box;
    text-shadow: 1.5px 1.5px #333333;
  }
  .resource-bg {
    background-color: grey;
  }
`;

const LaboThumbnailCloud = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1382px) {
    flex-direction: column;
  }

`;

export { AuthVariableInput, JoyrideStart, ExpeCloud, LaboThumbnail as LaboThumbnail, LaboThumbnailCloud };
