import React, { useState } from "react";
import styled from "styled-components";
import { LoginButton } from "./login";
import { isBrowser } from "./constants";

const BaseUserMessage = styled.p`
  background-color: white;
  padding: 1em;
  border-radius: 1em;
  text-align: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NoLogin = styled(BaseUserMessage)`
  background-color: grey;
  color: white;
  em {
    font-size: 1.5em;
    color: #fdd017;
    font-style: normal;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const NoLoginMsg = ({ refProp }) => {
  return (
    <MessageWrapper>
      <NoLogin ref={refProp}>
        <em>🔒 </em>Unauthenticated users can't access the lab experiments:{" "}
        <LoginButton />
      </NoLogin>
    </MessageWrapper>
  );
};

const Error = styled(BaseUserMessage)`
  color: white;
  background-color: rgba(255, 0, 0, 0.8);
  em {
    color: red;
    font-style: normal;
  }
`;

const InstructionStyle = styled(BaseUserMessage)`
  color: black;
  background-color: #8ac176;
  text-align: left;
`;

const Instruction = ({ children }) => {
  return (
    <InstructionStyle>
      <h2>Instruction</h2>
      <p>{children}</p>
    </InstructionStyle>
  );
};

const ErrorMessage = ({ refProp }) => {
  return (
    <Error ref={refProp}>
      {" "}
      Server-side error, apologizes for the inconvenience
    </Error>
  );
};

const ExhaustedCredit = styled(BaseUserMessage)`
  color: white;
  background-color: #af69ee;
  em {
    font-style: normal;
  }
`;

const ExhaustedCreditMessage = () => {
  return (
    <ExhaustedCredit>
      <em>💸</em> You ran out of credits!
    </ExhaustedCredit>
  );
};

function DynamicMessage({ msgState, refProp }) {
  switch (msgState) {
    case "no_credits":
      return <ExhaustedCreditMessage ref={refProp} />;
    case "error":
      return <ErrorMessage refProp={refProp} />;
    case "unauthenticated":
      return <NoLoginMsg refProp={refProp} />;
    case "":
      return <div ref={refProp}></div>;
    default:
      return <Error ref={refProp}>{msgState}</Error>;
  }
}

function useMsgState(initUserMsg, msgRef) {
  const [userMsg, setUserMsg] = useState(initUserMsg);
  async function setUserMsgAndScroll(newValue) {
    setUserMsg(newValue);
    if (isBrowser) {
      msgRef.current.scrollIntoView({ block: "center", inline: "nearest" });
    }
  }
  return [userMsg, setUserMsgAndScroll];
}

export {
  NoLoginMsg,
  ErrorMessage,
  ExhaustedCreditMessage,
  DynamicMessage,
  Instruction,
  useMsgState,
};
